<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="h1">
        黑龙江省放射影像专业质控中心专家委员会<br />工作交流会胜利召开
      </div>
      <section>
        <div class="div_p">
          2024年3月30日，由黑龙江省放射影像专业医疗质量控制中心主办，哈尔滨医科大学附属第一医院承办的黑龙江省放射影像专业医疗质量控制中心专家委员会工作交流会在哈尔滨市隆重召开，黑龙江省放射影像质控中心专家委员会全体委员参加了会议，2月末刚刚在线上召开了新一届质控中心成立大会暨第一次全体专家委员会工作会议，这是成立后第一次线下交流会。会议还邀请到了吉林省影像科质量控制中心主任、吉林大学白求恩第三医院放射科主任柳林教授和北京市医学影像质量控制和改进中心办公室主任、北京友谊医院魏璇副教授做质控工作经验介绍。
        </div>
        <div>
          <img src="@/assets/HLJZJH/1.png" alt="">
        </div>
        <div class="div_tit">
          与会专家合影
        </div>
        <div class="div_p">
          会议开幕式由黑龙江省放射影像专业质控中心主任肖喜刚教授主持，国家放射影像质控中心主任、中国放射医师分会会长、北京协和医院金征宇教授一直关注着黑龙江的质控工作，本次会议特发来视频表达了对肖喜刚教授当选新一届黑龙江省放射影像专业质控中心主任的祝贺，同时指出放射影像专业质控是互通、互联、互认的基础，希望省放射影像专业质控中心能再接再厉，推动龙江影像事业勇攀高峰。
        </div>
        <div>
          <img src="@/assets/HLJZJH/2.png" alt="">
        </div>
        <div class="div_tit">
          金征宇教授致辞
        </div>
        <div class="div_p">
          省放射影像质控中心主任肖喜刚教授介绍了召开本次会议的目的及意义，分析了黑龙江省放射影像质控工作的现状，指出了黑龙江省与国内部分省份在放射影像质控工作方面有一定差距，主要原因是起步晚，基础薄弱，同时还阐述了放射影像质控工作开展所面临的形势与挑战，并表示了对龙江放射影像质控工作的未来充满信心。
        </div>
        <div>
          <img src="@/assets/HLJZJH/3.png" alt="">
        </div>
        <div class="div_tit">
          肖喜刚教授致辞
        </div>
        <div class="div_p">
          吉林省医学影像科质量控制中心主任柳林教授带来了题为吉林省质控中心工作经验分享的专题讲座，内容涵盖了国内外放射影像专业质控中心的发展历史、职责职能和工作内容等方面，尤其重点讲述了吉林省医学影像科质量控制中心近年来所做的工作，包括组织框架、业务培训、调研考察、平台建设等内容，既介绍了宝贵的成功经验，也剖析了部分问题产生的原因，讲座后与大家进行了交流，详细地回答了与会委员的提问。
        </div>
        <div>
          <img src="@/assets/HLJZJH/4.png" alt="">
        </div>
        <div class="div_tit">
          柳林教授讲座
        </div>
        <div class="div_p">
          北京市医学影像质量控制和改进中心办公室主任魏璇副教授讲授了基于北京市医学影像云平台质控面临的机遇及挑战，从北京市影像质控中心的组织结构、专家例会到考核标准逐一进行详细讲解，并通过大量准确详实的数据为龙江同道解析了北京市影像云平台在质控工作中应用，深度剖析了数据背后的意义，并对未来龙江质控平台建设和质控与科研的关系提出了宝贵的经验。黑龙江省放射影像专业质控中心副主任王巍教授主持了专家讲座。
        </div>
        <div>
          <img src="@/assets/HLJZJH/5.png" alt="">
        </div>
        <div class="div_tit">
          魏璇副教授讲座
        </div>
        <div class="div_p">
          随后进行了黑龙江省放射影像专业质控中心工作会议环节，由质控中心秘书朱凯副教授主持。会议内容包括放射影像专业质控中心的制度建设和2024年省放射影像专业质控中心的工作要点两方面。主要议题包括如何完成《2024年国家医疗质量安全改进目标》中提出的提高放射影像诊断符合率、推进完成2024年国家卫健委发布的专业质控中心质控指标和建立我省危急值申报流程和疾病谱等。与会委员针对问题展开了激烈的交流与探讨，省内部分地市质控工作负责人结合自己本地医学影像质控工作的经验，提出了今后工作中可能遇到的问题，指出了未来工作的目标，并初步达成共识。
        </div>
        <div>
          <img src="@/assets/HLJZJH/6.png" alt="">
        </div>
        <div class="div_tit">
          工作会议讨论现场
        </div>
        <div class="div_p">
          本次会议的召开，是黑龙江省放射影像专业医疗质控中心进一步健全质控体系和工作机制的重要体现。该中心将立足本次会议主要内容不断探索实践，加强与国内兄弟放射影像专业医疗质控中心的交流合作，强化质量管理，发挥专业支撑作用。“龙影腾飞，质控先行”，为龙江放射专业的高质量发展奠定更为坚实的基础。
        </div>
      </section>

    </div>
  </div>
</template>

<script>
export default {
  name: "HLJZJH",

}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin-bottom: 10px;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>